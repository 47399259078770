import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Image, Center  } from '@chakra-ui/react';
import Web3 from 'web3';
import ConnectWalletWarning from './ConnectWalletWarning';
import MemeGenerator from './MemeGenerator';




const Navbar = () => {
  const { isOpen: isColonyOpen, onOpen: onColonyOpen, onClose: onColonyClose } = useDisclosure();
  const { isOpen: isConflictOpen, onOpen: onConflictOpen, onClose: onConflictClose } = useDisclosure();
  const { isOpen: isAdventureOpen, onOpen: onAdventureOpen, onClose: onAdventureClose } = useDisclosure();
  const { isOpen: isMysteryOpen, onOpen: onMysteryOpen, onClose: onMysteryClose } = useDisclosure();
  const { isOpen: isGenerator, onOpen: onGeneratorOpen, onClose: onGeneratorClose } = useDisclosure();

  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [balance, setBalance] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);


  useEffect(() => {
    checkWalletConnection();
    checkNetwork();
  }, []);

  const checkWalletConnection = async () => {
    const provider = window.ethereum;
    if (provider) {
      const accounts = await provider.request({ method: 'eth_accounts' });
      setIsConnected(accounts.length > 0);
    }
  };

  const checkNetwork = async () => {
    const provider = window.ethereum;
    if (provider) {
      const chainId = await provider.request({ method: 'eth_chainId' });
      setIsCorrectNetwork(chainId === '0xee'); // Change to the correct chainId for the Blast network
    }
  };


   // Function to handle connecting to MetaMask
   const connectWalletHandler = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        setWeb3(web3);
        setAccounts(accounts);
        const balance = await web3.eth.getBalance(accounts[0]);
        setBalance(web3.utils.fromWei(balance, 'ether'));
      } catch (error) {
        console.error("Connection to MetaMask failed", error);
      }
    } else {
      console.log('Please install MetaMask!');
    }
  }; 

    // Disconnect wallet
    const disconnectWalletHandler = () => {
        if (window.confirm("Are you sure you want to disconnect your wallet?")) {
          setWeb3(null);
          setAccounts([]);
          setBalance('');
        }
      };
    

  useEffect(() => {
    if (web3 && accounts.length > 0) {
      const loadBalance = async () => {
        const balance = await web3.eth.getBalance(accounts[0]);
        setBalance(web3.utils.fromWei(balance, 'ether'));
      };
      loadBalance();
    }
  }, [web3, accounts]);

  return (
    <>
    <Flex as="nav" bg="black" color="White" align="center" justify="space-between" padding="1rem">
      <Box><Image src="CBz.png" height={"50px"} alt="logo" /></Box>
      <Flex gap="20px">
        <Text cursor="pointer" onClick={onColonyOpen}>Colony</Text>
        <Text cursor="pointer" onClick={onConflictOpen}>Conflict</Text>
        <Text cursor="pointer" onClick={onAdventureOpen}>Adventure</Text>
        <Text cursor="pointer" onClick={onMysteryOpen}>Mystery</Text>
        <a href="https://docs.google.com/document/d/15ZVl67nAQqQv3BCfIYM4ElSH_NUu-V6nCWa2nHMzoRQ/edit?usp=sharing"  style={{ textDecoration: 'none', color: 'Yellow' }} target="_blank" rel="noreferrer"> Yolla Paper </a>
        <Text cursor="pointer" onClick={onGeneratorOpen}>Meme Generator</Text>
      </Flex>
      {accounts.length === 0 ? (
        <Button colorScheme="yellow" onClick={connectWalletHandler}>Connect</Button>
      ) : (
        <Button colorScheme="teal" onClick={disconnectWalletHandler}>
          {balance.slice(0, 10)} ETH {accounts[0].slice(0, 5) + '...' + accounts[0].slice(-4)}
        </Button>
      )}

<Modal isOpen={isColonyOpen} onClose={onColonyClose} isCentered>
  <ModalOverlay />
  <ModalContent width="auto" maxWidth="650px" p={0}>
    <ModalHeader textAlign={"center"}>The Colony</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <Center>
        <Image src={'Colony.png'} alt={"The Colony"} />
      </Center>
    </ModalBody>
  </ModalContent>
</Modal>

<Modal isOpen={isConflictOpen} onClose={onConflictClose} isCentered>
  <ModalOverlay />
  <ModalContent width="auto" maxWidth="650px" p={0}>
    <ModalHeader textAlign={"center"}>The Conflict</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <Center>
        <Image src={'Conflict.png'} alt={"The Conflict"} />
      </Center>
    </ModalBody>
  </ModalContent>
</Modal>

<Modal isOpen={isAdventureOpen} onClose={onAdventureClose} isCentered>
  <ModalOverlay />
  <ModalContent width="auto" maxWidth="650px" p={0}>
    <ModalHeader textAlign={"center"}>The Adventure</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <Center>
        <Image src={'Adventure.png'} alt={"The Adventure"} />
      </Center>            
    </ModalBody>
  </ModalContent>
</Modal>

<Modal isOpen={isMysteryOpen} onClose={onMysteryClose} isCentered>
  <ModalOverlay />
  <ModalContent width="auto" maxWidth="650px" p={0}>
    <ModalHeader textAlign={"center"}>The Mystery</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <Center>
        <Image src={'Mystery.png'} alt={"The Mystery"} />
      </Center>
    </ModalBody>
  </ModalContent>
</Modal>

<Modal isOpen={isGenerator} onClose={onGeneratorClose} isCentered>
  <ModalOverlay />
  <ModalContent width="auto" maxWidth="1000px" p={0}>
    <ModalHeader textAlign={"center"}>Coke Bee 🐝 Meme Generator</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <MemeGenerator />
    </ModalBody>
  </ModalContent>
</Modal>



    </Flex>
    <ConnectWalletWarning isConnected={isConnected} isCorrectNetwork={isCorrectNetwork} />

    </>
  );
};

export default Navbar;
