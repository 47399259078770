import React, { useState } from 'react';
import {
  Box,
  Button,
  Image,
  Input,
  Select,
  VStack,
  Text,
  Flex,
  useToast
} from '@chakra-ui/react';
import html2canvas from 'html2canvas';

const backgrounds = [
  { label: "Army", value: "/assets/Backgrounds/Army.png" },  
  { label: "Bumpkin Spice", value: "/assets/Backgrounds/Bumpkin-Spice.png" },  
  { label: "Honey Comb", value: "/assets/Backgrounds/Honey-Comb.png" },  
  { label: "Larvee", value: "/assets/Backgrounds/Larvee.png" },  
  { label: "Pure Gold", value: "/assets/Backgrounds/Pure-Gold.png" },  
  { label: "Sky", value: "/assets/Backgrounds/Sky.png" },  
  { label: "Spiced", value: "/assets/Backgrounds/Something.png" },
];
const characters = [
  { label: "Honey Bee", value: "/assets/Face/Honey-Bee.png" },
  { label: "Killer Bee", value: "/assets/Face/Killer-Bee.png" },
  { label: "Worker Bee", value: "/assets/Face/Bee.png" },
  { label: "Bumble Bee", value: "/assets/Face/Bumble-Bee.png" },
  { label: "Bumpkin Bee", value: "/assets/Face/Bumpkin-Bee.png" },
  { label: "Blue Carpentar Bee", value: "/assets/Face/Blue-Carpentar-Bee.png" },
];

const innerEyes = [
  { label: "Blue", value: "/assets/InnerEyes/Blue.png" },
  { label: "Red", value: "/assets/InnerEyes/Red.png" },
  { label: "Honey", value: "/assets/InnerEyes/Honey.png" },
  { label: "King Bee", value: "/assets/InnerEyes/King-Bee.png" },
  { label: "None", value: "/assets/InnerEyes/None.png" },
]

const outterEyes = [
  { label: "Little Bee", value: "/assets/OutterEyes/Little-Bee.png" },  
  { label: "Blasted", value: "/assets/OutterEyes/Blasted.png" },
  { label: "Blast Off!", value: "/assets/OutterEyes/Blast-Off.png" },
  { label: "Dead", value: "/assets/OutterEyes/Dead.png" },
  { label: "Skeeeeeeed", value: "/assets/OutterEyes/Skeed.png" },
  { label: "Zombie", value: "/assets/OutterEyes/Zombie.png" },
]

const mouths = [
  { label: "Happy", value: "/assets/Mouths/Happy.png" },
  { label: "Crabby", value: "/assets/Mouths/Crabby.png" },
  { label: "Jaw", value: "/assets/Mouths/Jaw.png" },
  { label: "Killer Beez", value: "/assets/Mouths/Killer-Beez.png" },
  { label: "Opened", value: "/assets/Mouths/Open-Mouth.png" },
  { label: "Sad", value: "/assets/Mouths/Sad.png" },
  { label: "Shocked", value: "/assets/Mouths/Shocked.png" },
  { label: "Zuckerberged", value: "/assets/Mouths/Zucked.png" },
]


const paraphernalias = [
  { label: "Nada", value: "/assets/Paraphernalia/Nada.png" },
  { label: "Razor", value: "/assets/Paraphernalia/Razor.png" },
  { label: "Straw", value: "/assets/Paraphernalia/Straw.png" },
  { label: "Scale", value: "/assets/Paraphernalia/Scale.png" },
  { label: "Snow", value: "/assets/Paraphernalia/Snow.png" },
]

const habits = [
  { label: "Straight Edge", value: "/assets/Habits/Straight-Edge.png" },
  { label: "Like It!", value: "/assets/Habits/Like-The-Smell.png" },
  { label: "Love it!", value: "/assets/Habits/Love-The-Smell.png" },
]

const brands = [
  { label: "None", value: "/assets/Brand/None.png" },
  { label: "Coke Bee's", value: "/assets/Brand/CokeBees.png" },
]

const MemeGenerator = () => {
  const [background, setBackground] = useState(backgrounds[0].value);
  const [character, setCharacter] = useState(characters[0].value);
  const [innerEye, setInnerEye] = useState(innerEyes[0].value);
  const [outterEye, setOutterEye] = useState(outterEyes[0].value);
  const [mouth, setMouth] = useState(mouths[0].value);
  const [paraphernalia, setParaphernalia] = useState(paraphernalias[0].value);
  const [habit, setHabit] = useState(habits[0].value);
  const [brand, setBrand] = useState(brands[0].value);

  const [fontColor, setFontColor] = useState('white'); // Default color


  const [topText, setTopText] = useState('');
  const [bottomText, setBottomText] = useState('');
  const toast = useToast();

  const toggleFontColor = () => {
    setFontColor(fontColor === 'white' ? 'black' : 'white');
  };
  

  const tweetMeme = () => {
    const text = encodeURIComponent("Check out this $Coke Bee meme I made using the #CokeBeeMemeGenerator Get Blasted with the #CokeBees");
    const url = encodeURIComponent(window.location.href); // URL of your app or where the meme can be seen
    window.open(`https://twitter.com/intent/tweet?text=${text}&url=${url}`, '_blank');
  };

    // Function to check all selections are made
    const allSelected = () => {
        return background && character && innerEye && outterEye && mouth && paraphernalia && habit && brand;
      };

  // Function to provide visual cues for missing selections
  const selectionBorderColor = (selection) => selection ? "gray.200" : "red.500";

  const handleRandomize = () => {
    const randomizeSelection = (array) => array[Math.floor(Math.random() * array.length)].value;

    setBackground(randomizeSelection(backgrounds));
    setCharacter(randomizeSelection(characters));
    setInnerEye(randomizeSelection(innerEyes));
    setOutterEye(randomizeSelection(outterEyes));
    setMouth(randomizeSelection(mouths));
    setParaphernalia(randomizeSelection(paraphernalias));
    setHabit(randomizeSelection(habits));
    setBrand(randomizeSelection(brands));
  };

  

  const handleSave = async () => {
    if (!allSelected()) {
        toast({
          title: "Incomplete Selection",
          description: "Please make a selection from each category before saving.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      

    const canvas = await html2canvas(document.querySelector("#memePreview"));
    const image = canvas.toDataURL("image/png");
    const link = document.createElement('a');
    link.href = image;
    link.download = 'my-CokeBee-meme.png';
    link.click();

    toast({
      title: "Meme saved!",
      description: "Your Coke Bee meme has been saved to your device.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    tweetMeme();
  };

  return (
    <Flex p={4}>
      <VStack w="30%" spacing={4}>
        <Select onChange={(e) => setBackground(e.target.value)} placeholder="Select Background"  borderColor={selectionBorderColor(background)}>
          {backgrounds.map(opt => (
            <option key={opt.value} value={opt.value}>{opt.label}</option>
          ))}
        </Select>
        <Select onChange={(e) => setCharacter(e.target.value)} placeholder="Select Character"  borderColor={selectionBorderColor(character)}>
          {characters.map(opt => (
            <option key={opt.value} value={opt.value}>{opt.label}</option>
          ))}
        </Select>
        <Select onChange={(e) => setInnerEye(e.target.value)} placeholder="Select Inner Eye"  borderColor={selectionBorderColor(innerEye)}>
          {innerEyes.map(opt => (
            <option key={opt.value} value={opt.value}>{opt.label}</option>
          ))}
        </Select>
        <Select onChange={(e) => setOutterEye(e.target.value)} placeholder="Select Outter Eye"  borderColor={selectionBorderColor(outterEye)}>
          {outterEyes.map(opt => (
            <option key={opt.value} value={opt.value}>{opt.label}</option>
          ))}
        </Select>
        <Select onChange={(e) => setMouth(e.target.value)} placeholder="Select Mouth"  borderColor={selectionBorderColor(mouth)}>
          {mouths.map(opt => (
            <option key={opt.value} value={opt.value}>{opt.label}</option>
          ))}
        </Select>
        <Select onChange={(e) => setParaphernalia(e.target.value)} placeholder="Select Paraphernalia"  borderColor={selectionBorderColor(paraphernalia)}>
          {paraphernalias.map(opt => (
            <option key={opt.value} value={opt.value}>{opt.label}</option>
          ))}
        </Select>
        <Select onChange={(e) => setHabit(e.target.value)} placeholder="Select Habit level"  borderColor={selectionBorderColor(habit)}>
          {habits.map(opt => (
            <option key={opt.value} value={opt.value}>{opt.label}</option>
          ))}
        </Select>
        <Select onChange={(e) => setBrand(e.target.value)} placeholder="Brand Image"  borderColor={selectionBorderColor(brand)}>
          {brands.map(opt => (
            <option key={opt.value} value={opt.value}>{opt.label}</option>
          ))}
        </Select>

        <Input placeholder="Top Text" value={topText} onChange={(e) => setTopText(e.target.value)} />
        <Input placeholder="Bottom Text" value={bottomText} onChange={(e) => setBottomText(e.target.value)} />
        <Button onClick={handleRandomize} colorScheme="purple">Randomize</Button>
        <Button onClick={toggleFontColor} colorScheme="gray">{`Switch Font Color to ${fontColor === 'white' ? 'Black' : 'White'}`}</Button>
        <Button onClick={handleSave} colorScheme="blue" disabled={!allSelected()}>Save Meme</Button>
      </VStack>
      <Box id="memePreview" flex="1" marginLeft={"10px"} position="relative" border="2px solid black">
        <Image src={background} fit="cover" w="100%" h="100%" />
        <Image src={character} position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" />
        <Image src={innerEye} position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" />
        <Image src={outterEye} position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" />
        <Image src={mouth} position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" />
        <Image src={paraphernalia} position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" />
        <Image src={habit} position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" />
        <Image src={brand} position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" />
        
        <Text position="absolute" top="3%" w="100%" textAlign="center" fontSize="2xl" color={fontColor} fontFamily="Poppins, sans-serif">{topText}</Text>
        <Text position="absolute" bottom="3%" w="100%" textAlign="center" fontSize="2xl" color={fontColor} fontFamily="Poppins, sans-serif">{bottomText}</Text>
      </Box>
    </Flex>
  );
};

export default MemeGenerator;
