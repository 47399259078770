import React, { useState, useEffect } from 'react';
import { Box, Flex, Image, Text, Progress, Button, Link, Icon, Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, 
 ModalBody, ModalCloseButton, useDisclosure, SimpleGrid   } from '@chakra-ui/react';
import { FaTwitter, FaDiscord, FaBuysellads, FaFileContract } from 'react-icons/fa';
import contractABI from '../ABI/contractAbi.json';
import Web3 from 'web3';
import PriceSelector from './PriceSelector';

const CONTRACT_ADDRESS = '0x6e6064f17c9c24f947d25b523defe76679529f1e';
const web3 = new Web3(window.ethereum);
const contract = new web3.eth.Contract(contractABI, CONTRACT_ADDRESS);
const apiKey = process.env.REACT_APP_API_KEY;




const MintButton = ({  }) => {
    const [accounts, setAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [myNfts, setNfts] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const checkAccounts = async () => {

            const accounts = await web3.eth.getAccounts();
            setAccounts(accounts);
        };

        //Initial check
        checkAccounts();
        // Listen for account changes
        const handleAccountsChanged = (accounts) => {
            setAccounts(accounts);
            if (accounts.length === 0) {
                console.log("Please connect to MetaMask.");
                alert ("Please connect to MetaMask.");
            } else {
                alert("Account connected:", accounts[0]);
                console.log("Account connected:", accounts[0]);
                
            }
        };
        
        window.ethereum.on('accountsChanged', handleAccountsChanged);

        return () => {
            // Cleanup the listener
            window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        };
    }, []);

    useEffect(() => {

        const fetchNFTOwnership = async () => {
            if (accounts.length > 0) {
                console.log('Fetching NFT ownership...');
                try {
                    const address = accounts[0];
                    const balance = await contract.methods.balanceOf(address).call();
                    const nftsOwned = [];

    
                    if (balance > 0) {
                        //console.log(`User ${address} owns ${balance} NFT(s).`);
                        // Optionally, fetch specific NFT IDs if needed:
                        for (let i = 0; i < balance; i++) {
                            const tokenId = await contract.methods.tokenOfOwnerByIndex(address, i).call();
                            const tokenURI = await contract.methods.tokenURI(tokenId).call(); // using tokenURI method
                           const httpURL = tokenURI.replace(/^ipfs:\/\/(.+)/, `https://aqua-historic-chipmunk-165.mypinata.cloud/ipfs/$1?pinataGatewayToken=${apiKey}`);
                        
                           const metadata = await fetch(httpURL).then(response => response.json());

                           if(!metadata) {
                            console.error("Error fetching NFT metadata:", metadata);
                            return;
                           }

                            nftsOwned.push({
                                id: tokenId,
                                name: metadata.name,
                                image: metadata.image.replace(/^ipfs:\/\/(.+)/, `https://aqua-historic-chipmunk-165.mypinata.cloud/ipfs/$1?pinataGatewayToken=${apiKey}`),
                            });

                            console.log(`Owned Token ID at index ${i}: ${tokenId}`);
                        }

                        setNfts(nftsOwned);
                        //console.log("my NFTs",myNfts);

                    } else {
                        console.log("User does not own any NFTs.");
                    }
                } catch (error) {
                    console.error("Error fetching NFT ownership:", error);
                }
            }
        };
    
        fetchNFTOwnership();
    }, [accounts]);  // Depend on accounts to re-run when accounts change
    



    const connectWallet = async () => {
        try {
            const accounts = await web3.eth.requestAccounts();
            setAccounts(accounts);
            return accounts;
        } catch (error) {
            console.error("Error connecting to MetaMask:", error);
            return [];
        }
    };

    const mintNFT = async () => {
        
        if (!accounts.length) {
            const retrievedAccounts = await connectWallet();
            if (!retrievedAccounts.length) {
                alert('No accounts available. Make sure MetaMask is connected.');
                return;
            }
        }

        setIsLoading(true); // Start loading before the minting process starts

    
        //Convert price to Wei and ensure it is a string to prevent BigInt issues
       //const priceInWei = web3.utils.toWei((pricePerUnit * amountToMint).toString(), 'ether');
    
        const options = {
            from: accounts[0],
            gasPrice: (await web3.eth.getGasPrice()).toString()  // Convert gas price to string
        };
    
        try {
            const gasEstimate = await contract.methods.mintFree().estimateGas(options);
            options.gas = Math.floor(Number(gasEstimate) * 1.2).toString(); // Convert to number, apply buffer, convert to string
    
            const receipt = await contract.methods.mintFree().send(options);
             if(receipt) {
            alert('Minted successfully!: ', receipt);    
            console.log('Transaction receipt:', receipt);
        }
        } catch (error) {
            console.error('Error minting NFT:', error);
            alert('Error during minting: ' + error.message);
        } finally {
            setIsLoading(false); // Stop loading regardless of the outcome
        }

        
    };
    

    return (
        <Box textAlign="center" width={"100%"}>
        {isLoading ? (
            // Display spinner when transaction is in progress
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        ) : accounts.length > 0 ? (
            // Display mint button when the wallet is connected and not loading
            <><Button onClick={mintNFT} colorScheme="teal" width="80%">
                Free Mint
            </Button>
          {myNfts.length > 0 &&   <Button onClick={onOpen} mt="4">
                You have minted {myNfts.length} NFTs
            </Button> }
            <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>My Coke Bee's</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody maxH="500px" overflowY="auto">
                                <SimpleGrid columns={2} spacing={4}>
                                {myNfts.map(nft => (
                                  //  console.log(nft.image),
                                    <Box key={nft.id} p="5" borderWidth="1px" borderRadius="lg" overflow="hidden"  cursor="pointer"
                                    onClick={() => window.open(`https://opensea.io/assets/blast/0x6e6064f17c9c24f947d25b523defe76679529f1e/${nft.id}`, '_blank')}
                                    display="flex" flexDirection="column" alignItems="center" justifyContent="center" shadow="lg"
                                    transition="transform 0.2s ease-in-out"  // Add transition for smooth scaling
                                     _hover={{ transform: 'scale(1.05)' }}
                                    >
                                        <Image src={nft.image} alt={nft.name}  crossOrigin="anonymous" boxSize="150px" borderRadius={"10px"} objectFit="cover" mb="3" />
                                        <Text fontSize="md">{nft.name}</Text>
                                    </Box>
                                ))}
                                </SimpleGrid>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
            </>
        ) : (
            // Display message when no wallet is connected
            <Text color="gray.200">Please connect wallet</Text>
        )}
    </Box>
    );
};




const MainContent = () => {  
  // State for minting amount and price
  const [currentMint, setCurrentMint] = useState(0);  // State to store the current number of mints
  const [amountToMint, setAmountToMint] = useState(1);
  const [pricePerUnit, setPricePerUnit] = useState(0);


  const totalMints = 4444;
  const mintPercentage = (currentMint / totalMints) * 100;

  useEffect(() => {
    const fetchTotalSupply = async () => {
      try {
        const supply = await contract.methods.totalSupply().call(); // Call the totalSupply method
        setCurrentMint(parseInt(supply, 10)); // Update state with the current number of mints
      } catch (error) {
        console.error("Error fetching total supply:", error);
      }
    };

    fetchTotalSupply();
  }, [currentMint]);

  // Function to update amount and price from PriceSelector
  const handleUpdate = (amount, price) => {
    setAmountToMint(amount);
    setPricePerUnit(price);
    console.log(amount, price);
  };

  return (
    <Flex justify="center" width="100%">
      <Flex width="80%" maxW="80%" bg="gray.900" p="5" align="center" justify="space-between" my="20px">
        <Box flex="1" p="3">
          <Text fontSize="xl" mb="4" color={"white"}>Get Blasted with the Coke Bee's</Text>
          <Flex justify="space-between" mb="4">
            <Text fontSize="lg" color={"white"}>Total Mint:</Text>
            <Text fontSize="lg" color={"white"}>{`${mintPercentage.toFixed(0)}% ${currentMint}/${totalMints}`}</Text>
          </Flex>
          <Progress colorScheme="green" size="lg" value={mintPercentage} mb="4" />

          <Text mb="2" color={"white"}>
            In 1986, a year after the infamous Cocaine Bear incident, a hidden transformation occurred in the Appalachian forests. Cocaine scattered across the land caused a strange chemical 
            reaction with the local flora, reshaping the region’s ecology. Dr. Buzz Lectar, an eccentric apiologist studying the effects of substances on bees, stumbled upon a hive feasting on 
            nectar from these altered flowers. The resulting special nectar supercharged the bees, creating the hyper-energetic and unpredictable Coke Bee’s. Infused with the chaotic energy of 
            the cocaine, these bees produced an extraordinary honey. This honey was not just exceptionally sweet but also charged with a mysterious power, bestowing unique abilities and appearances
             on each bee that partook.
          </Text>
          <Flex align="center" justify="space-evenly" mb="2">
            <Link href="https://twitter.com/CokeBees" isExternal><Icon as={FaTwitter} style={{ color: "#1DA1F2" }} w={6} h={6} /></Link>
            <Link href="https://discord.gg/wgy37SD5BP" isExternal><Icon as={FaDiscord} style={{ color: "#7289DA" }} w={6} h={6} /></Link>
            <Link href="https://blastscan.io/address/0x6e6064f17c9c24f947d25b523defe76679529f1e" isExternal><Icon as={FaFileContract} style={{ color: "#FFFFFF" }} w={6} h={6} /></Link>
          </Flex>
        </Box>
        <Box flex="1" p="3" textAlign="center" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Image src="/GM.png" alt="Sample Image" mb="4" boxSize="80%" objectFit="contain" />
          <PriceSelector onUpdate={handleUpdate} />
          {currentMint >= 2222 ? (
            <Text fontSize="lg" color="red.500" mt="4">
              Free mint is complete, check back soon!
            </Text>
          ) : (
            <MintButton amountToMint={amountToMint} pricePerUnit={pricePerUnit} />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default MainContent;
